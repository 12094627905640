import { ControllerBackupViewerUrlParams } from './controllerbackupviewer-utils';
import ControllerBackupViewer, { IControllerBackupViewerProps } from './controllerbackupviewer.react';
import { ReactWrapper } from '$pages/common/react-wrapper';

export class ControllerBackupViewerPage extends ReactWrapper<
  IControllerBackupViewerProps,
  ControllerBackupViewerUrlParams
> {
  constructor() {
    super(ControllerBackupViewer, (params) => ({
      params,
    }));
  }
}